import React from 'react';
import './style.sass';
import Image from 'gatsby-image';

import { StaticQuery, graphql } from 'gatsby'

function Tools() {
  return (
    <StaticQuery
      query={toolsQuery}
      render={data => {
        return (
          <div className="tools">
            <h3>Gereedschap en expertise</h3>
            <p>Met een <span role="img" aria-label="hammer">🔨</span> en <span role="img" aria-label="wrench">🔧</span> ben ik niet handig, maar met deze tools kan ik alles.</p>
            <ul>
              <li className="php"><Image fluid={data.php.childImageSharp.fluid} /></li>
              <li className="js"><Image fluid={data.js.childImageSharp.fluid} /></li>
              <li className="react"><Image fluid={data.react.childImageSharp.fluid} /></li>
              <li className="ga"><Image fluid={data.ga.childImageSharp.fluid} /></li>
              <li className="gtm"><Image fluid={data.gtm.childImageSharp.fluid} /></li>
              <li className="go"><Image fluid={data.go.childImageSharp.fluid} /></li>
              <li className="hj"><Image fluid={data.hj.childImageSharp.fluid} /></li>
            </ul>
            
          </div>
        )
      }}
    />
  )
}

const toolsQuery = graphql`
  query ToolsQuery {
    php: file(relativePath: { eq: "php.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    js: file(relativePath: { eq: "js.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    react: file(relativePath: { eq: "react.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ga: file(relativePath: { eq: "ga.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gtm: file(relativePath: { eq: "gtm.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    go: file(relativePath: { eq: "go.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hj: file(relativePath: { eq: "hj.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Tools