import React from 'react';
import './style.sass';

class Skills extends React.Component {
  render() {
    return (
      <div className="skills">
        <section className="card">
          <h2>Conversie-optimalisatie</h2>
          <p>Met A/B-testen weet je pas echt of iets werkt. Belangrijk is zorgvuldig te testen en de juiste conclusies te trekken. Zo kun je de strategie achter je website stapje voor stapje verbeteren.</p>
        </section>
        <section className="card">
          <h2>Analytics</h2>
          <p>Goede cijfers zijn onmisbaar om de juiste strategische beslissingen te kunnen nemen. Met een achtergrond in de psychologie weet ik cijfers op de juiste manier binnen de context te interpreteren.</p>
        </section>
        <section className="card">
          <h2>Usability &amp; UX</h2>
          <p>Ontwerpen maak ik met kennis uit de psychologie, waarbij de gebruiker centraal staat. Hierdoor zorg ik ervoor dat websites makkelijk te begrijpen zijn en leuk om te gebruiken.</p>
        </section>
        <section className="card">
          <h2>Development</h2>
          <p>Ik werk graag in code. Van niets iets bouwen is nog altijd magisch. Daarbij let ik vooral op snelheid. 40% van online bezoekers verlaten een site als deze er langer dan 3 sec. over doet om te laden. Deze site? Fijn dat je het vraagt: 800 ms. <span role="img" aria-label="happy face">😌</span></p>
        </section>
      </div>
    );
  }
}

export default Skills;
