import React from 'react';
import './style.sass';

class Hello extends React.Component {
  render() {
    return (
      <div className="hello">
        <p className="intro">Ik maak gebruikers van websites blij door te zorgen dat ze duidelijk en doelgericht zijn.</p>
        <p>Freelance developer en Web Analytics Specialist bij de <a href="https://efteling.com/">Efteling</a></p>
      </div>
    );
  }
}

export default Hello;
